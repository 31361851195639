import React from "react"
import gsap from "gsap"

class LoadingIcon extends React.Component {
    constructor(props){
        super(props)
    }

    componentDidMount() {
        gsap.fromTo('#circle1', {
            scale: 0,
            opacity: .35,
            transformOrigin: "center center"
        }, {
            scale: 1,
            opacity: 0,
            repeat: -1,
            delay: 0,
            repeatDelay: 0,
            duration: 3,
            ease: "none"
        })
        gsap.fromTo('#circle2', {
            scale: 0,
            opacity: .35,
            transformOrigin: "center center"
        }, {
            scale: 1,
            opacity: 0,
            repeat: -1,
            delay: -1,
            repeatDelay: 0,
            duration: 3,
            ease: "none"
        })
        gsap.fromTo('#circle3', {
            scale: 0,
            opacity: .35,
            transformOrigin: "center center"
        }, {
            scale: 1,
            opacity: 0,
            repeat: -1,
            delay: -2,
            repeatDelay: 0,
            duration: 3,
            ease: "none"
        })
    }

    render() {
        return (
            <div className="loading" style={{display: "flex"}}>
                <svg style={{margin: "auto"}} id="loading-icon" width="128" height="128" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <circle id="circle1" cx="16" cy="16" r="15" fill="rgb(147, 130, 78)"></circle>
                    <circle id="circle2" cx="16" cy="16" r="15" fill="rgb(147, 130, 78)"></circle>
                    <circle id="circle3" cx="16" cy="16" r="15" fill="rgb(147, 130, 78)"></circle>
                </svg>
            </div>
        )
    }
}

export default LoadingIcon