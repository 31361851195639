/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useLoader } from '@react-three/fiber'
import { useGLTF, OrbitControls } from '@react-three/drei'

export default function Model({ ...props }) {
    const group = useRef()
    const refMed = useRef()
    const orbitRef = useRef()
    const orpheyRef = useRef();
    const modelRef = useRef();
    const { nodes, materials } = useGLTF('./models/Flat/flat2.glb')

  useEffect(() => {
    if (orbitRef.current) {
      orbitRef.current.reset()
      orbitRef.current.target.y = -1.2;
      orbitRef.current.target.x = 0.3;
        orbitRef.current.maxDistance = 7;
        orbitRef.current.minDistance = 4.5;
        console.log(orbitRef.current, 'orbitRef.current')
    }
  }, [props.reset])

  useEffect(() => {
      console.log(orpheyRef.current, '');
      console.log(modelRef.current, 'rf');
  }, [props.orphey, props.orpheyStyle])

    useEffect(() => {
        console.log(refMed.current, '');
    }, [props.medallionFront])

    const fabricArray = [
        ['ADORNES-BLACK-min.jpg', [1, 1, 1]],
        ['ADORNES-GREEN-min.jpg', [0.6, 0.6, 0.6]],
        ['ADORNES-ORANGE-min.jpg', [0.9, 0.7, 0.9]],
        ['ADORNES-PURPLE-min.jpg', [0.55, 1, 0.55]],
        ['ADORNES-RED-min.jpg', [0.6, 1, 1]],
        ['ADORNES-SILVER-min.jpg', [1, 1, 0.8]],
        ['BRUGIA92-10-min.jpg', [1, 1, 1]],
        ['BRUGIA92-14-min.jpg', [1, 1, 1]],
        ['BRUGIA92-20-min.jpg', [1, 1, 1]],
        ['BRUGIA92-30-min.jpg', [1, 1, 1]],
        ['BRUGIA92-40-min.jpg', [1, 1, 1]],
        ['BRUGIA92-50-min.jpg', [1, 1, 1]],
        ['CANTATE-min.jpg', [1, 1, 1]],
        ['DUOMO-CREAM-min.jpg', [1, 1, 1]],
        ['DUOMO-GREEN-min.jpg', [1, 1, 1]],
        ['DUOMO-PURPLE-min.jpg', [1, 1, 1]],
        ['DUOMO-RED-min.jpg', [1, 1, 1]],
        ['DUPION-25-10-min.jpg', [1, 1, 1]],
        ['DUPION-25-14-min.jpg', [0.9, 0.9, 0.7]],
        ['DUPION-25-20-min.jpg', [0.35, 1, 1]],
        ['DUPION-25-23-min.jpg', [0.45, 1, 1]],
        ['DUPION-25-30-min.jpg', [0.7, 0.4, 0.8]],
        ['DUPION-25-33-min.jpg', [0.9, 0.5, 0.8]],
        ['DUPION-25-40-min.jpg', [6.5, 0.1, 1.4]],
        ['DUPION-25-42-min.jpg', [0.7, 1.5, 0.6]],
        ['DUPION-25-59-min.jpg', [0.85, 0.7, 0.6]],
        ['DUPION-25-81-min.jpg', [0.5, 0.5, 0.6]],
        ['PIUS-54-10-min.jpg', [1, 1, 1]],
        ['PIUS-54-14-min.jpg', [1, 1, 1]],
        ['PIUS-54-20-min.jpg', [1, 1, 1]],
        ['PIUS-54-30-min.jpg', [1, 1, 1]],
        ['PIUS-54-40-min.jpg', [1, 1, 1]],
        ['PIUS-54-101-min.jpg', [1, 1, 1]],
    ]

    function textureModel() {
        switch (props.texture) {
            case props.texture: return `./models/fabricNew/${fabricArray[props.texture][0]}`
        }
    }

  function medallionModel() {
      // console.log(props.medallionFront, 'props.medallionFront');
      switch (props.medallionFront) {
          case 0: return './models/medallionNew/22-6030_16cm_high.png';
          case 1: return './models/medallionNew/22-6030_16cm_high_beige.png';
          case 2: return './models/medallionNew/22-6030_16cm_high_purple.png';
          case 3: return './models/medallionNew/22-6030_16cm_high_red.png';
          case 4: return './models/medallionNew/22-6060_7,5cm_high.png';
          case 5: return './models/medallionNew/22-6063_10cm_high.png';
          case 6: return './models/medallionNew/22-6067_15cm_high.png';
          case 7: return './models/medallionNew/22-6067_15cm_high_green.png';
          case 8: return './models/medallionNew/22-6067_15cm_high_purple.png';
          case 9: return './models/medallionNew/22-6030_16cm_high.png';
      }
  }

  const orpheyArray = [
        '30-3002_2060_86mm_wide S1.png',
        '30-3002_2060_86mm_wide S1.png',

        '30-3002_3060_86mm_wide S1.png',

        '30-3002_4060_86mm_wide S1.png',

        '30-3002_6020_86mm-wide S1.png',

        '30-3002_8060_86mm-wide S1.png',

        '30-3006_2060_76mm_wide S1.png',

        '30-3006_3060_76mm_wide S1.png',

        '30-3006_4060_76mm_wide S1.png',

        '30-3006_8060_76mm_wide S1.png',

        '30-3010_1460_65mm_wide S1.png',

        '30-3010_2060_65mm_wide S1.png',

        '30-3010_3060_65mm_wide S1.png',

        '30-3010_4060_65mm_wide S1.png',

        '30-3014_5060_65mm_wide S1.png',

        '31-3122_2060_75mm-wide S1.png',

        '31-3122_3060_75mm-wide S1.png',

        '31-3122_4060_75mm-wide S1.png',

    ];


  function orpheyModel() {
      switch (props.orphey) {
          case props.orphey: return `./models/orpheysNew/${orpheyArray[props.orphey]}`
      }
  }

  const orphey = useLoader(THREE.TextureLoader, orpheyModel())
    // orphey.repeat.set(0.05, 0.15)
    orphey.repeat.set(0.05, 0.09)
    // orphey.center.set(.5, .5)
    orphey.center.set(.504, .614)
    // orphey.rotation = 1.57;
    if (orphey.rotation === 0) {
        orphey.rotation = 1.57;
        orphey.repeat.set(0.05, 0.09)
        orphey.center.set(.504, .614)
    }

    const orpheyUpper1 = (
        <mesh
            ref={orpheyRef}
            position={[-0.225, 3, -0.028]}
            scale={[0.315, 0.17, 1]}
        >
            <planeGeometry args={[2, 2, 2]} />
            <meshStandardMaterial
                attach="material"
                map={orphey}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
    )
    const orpheyUpper2 = (
        <mesh
            position={[1, 3, -0.028]}
            scale={[0.315, 0.17, 1]}
        >
            <planeGeometry args={[2, 2, 2]} />
            <meshStandardMaterial
                attach="material"
                map={orphey}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
    )
    const orpheyMiddle1 = (
        <mesh
            position={props.orpheyStyle === 5 ? [-0.225, -1.7, -0.028] : [-0.225, 0.5, -0.028]}
            scale={[0.315, 0.17, 1]}
        >
            <planeGeometry args={[2, 2, 2]} />
            <meshStandardMaterial
                attach="material"
                map={orphey}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
    )
    const orpheyMiddle2 = (
        <mesh
            position={props.orpheyStyle === 5 ? [1, -1.7, -0.023] : [1, 0.5, -0.028]}
            scale={[0.315, 0.17, 1]}
        >
            <planeGeometry args={[2, 2, 2]} />
            <meshStandardMaterial
                attach="material"
                map={orphey}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
    )
    const orpheyBottom1 = (
        <mesh
            position={(props.orpheyStyle === 4 || props.orpheyStyle === 5) ? [-0.225, -2.48, -0.028] : [-0.225, -2, -0.028]}
            scale={[0.315, 0.17, 1]}
        >
            <planeGeometry args={[2, 2, 2]} />
            <meshStandardMaterial
                attach="material"
                map={orphey}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
    )
    const orpheyBottom2 = (
        <mesh
            position={(props.orpheyStyle === 4 || props.orpheyStyle === 5) ? [1, -2.48, -0.023] : [1, -2, -0.028]}
            scale={[0.315, 0.17, 1]}
        >
            <planeGeometry args={[2, 2, 2]} />
            <meshStandardMaterial
                attach="material"
                map={orphey}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
    )

  const medallionTestType = useLoader(THREE.TextureLoader, medallionModel())
    medallionTestType.repeat.set(0.08, 0.12)
    medallionTestType.center.set(0.5, 0.783)

  const medallionUpperTest = (
      <mesh
          renderOrder={1}
          position={[-0.22, 3, -0.025]}
          scale={[0.35, 0.35, 0.35]}
      >
          <planeGeometry args={[1, 1, 1]} />
          <meshStandardMaterial
              attach="material"
              map={medallionTestType}
              transparent={true}
              side={THREE.FrontSide}
          />
      </mesh>
  )

  const medallionUpperTest2 = (
      <mesh
          renderOrder={1}
          position={[1.009, 3, -0.025]}
          scale={[0.35, 0.35, 0.35]}
      >
            <planeGeometry args={[1, 1, 1]} />
            <meshStandardMaterial
                attach="material"
                map={medallionTestType}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
  )
  const medallionBottomTest = (
      <mesh
          ref={refMed}
          renderOrder={1}
          position={[-0.22, -2, -0.025]}
          scale={[0.35, 0.35, 0.35]}
      >
            <planeGeometry args={[1, 1, 1]} />
            <meshStandardMaterial
                attach="material"
                map={medallionTestType}
                transparent={true}
                side={THREE.FrontSide}
            />
        </mesh>
  )
  const medallionBottomTest2 = (
      <mesh
          renderOrder={1}
          position={[1.009, -2, -0.025]}
          scale={[0.35, 0.35, 0.35]}
      >
          <planeGeometry args={[1, 1, 1]} />
          <meshStandardMaterial
              attach="material"
              map={medallionTestType}
              transparent={true}
              side={THREE.FrontSide}
          />
      </mesh>
  )

  const texture = useLoader(THREE.TextureLoader, textureModel())
  texture.center.set(.5, .5)
  texture.rotation = Math.PI
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
          ref={modelRef}
        geometry={nodes.flat.geometry}
        position={[0.41, 2.71, 0]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.075, -0.075, -0.075]}>
      >
        <meshStandardMaterial attach="material" color={fabricArray[props.texture][1]} map={texture} transparent={false} side={THREE.DoubleSide} metalness={props.texture >= 13 && props.texture <= 16 ? 0.7 : 0} roughness={props.texture >= 13 && props.texture <= 16 ? 0.6 : 0.99} />
      </mesh>
      <mesh
        geometry={nodes.chain.geometry}
        position={[0.41, 2.71, 0]}
        rotation={[-Math.PI, 0, 0]}
        scale={[-0.075, -0.075, -0.075]}
      >
        <meshStandardMaterial attach="material" transparent={false} side={THREE.DoubleSide} metalness={props.texture >= 13 && props.texture <= 16 ? 0.7 : 0} roughness={props.texture >= 13 && props.texture <= 16 ? 0.6 : 1} />
      </mesh>
        {props.orphey > 0 && (props.orpheyStyle === 0 || props.orpheyStyle === 2 || props.orpheyStyle === 3) ? orpheyUpper1 : null}
        {props.orphey > 0 && (props.orpheyStyle === 0 || props.orpheyStyle === 2 || props.orpheyStyle === 3) ? orpheyUpper2 : null}
        {props.orphey > 0 && (props.orpheyStyle === 3 || props.orpheyStyle === 5) ? orpheyMiddle1 : null}
        {props.orphey > 0 && (props.orpheyStyle === 3 || props.orpheyStyle === 5) ? orpheyMiddle2 : null}
        {props.orphey > 0 && props.orpheyStyle !== 0 ? orpheyBottom1 : null}
        {props.orphey > 0 && props.orpheyStyle !== 0 ? orpheyBottom2 : null}

        {props.medallionFront > 0 && (props.medallionPlacement === 0 || props.medallionPlacement === 2) ? medallionUpperTest : null}
        {props.medallionFront > 0 && (props.medallionPlacement === 0 || props.medallionPlacement === 2) ? medallionUpperTest2 : null}
        {props.medallionFront > 0 && (props.medallionPlacement === 1 || props.medallionPlacement === 2) ? medallionBottomTest : null}
        {props.medallionFront > 0 && (props.medallionPlacement === 1 || props.medallionPlacement === 2) ? medallionBottomTest2 : null}
      <OrbitControls ref={orbitRef} enableZoom={true} />
    </group>
  )
}

useGLTF.preload('./models/Flat/flat2.glb')
