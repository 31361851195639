/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useLoader } from '@react-three/fiber'
import { useGLTF, OrbitControls } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const orbitRef = useRef()
  const refOrph = useRef()
  const { nodes } = useGLTF('./models/Dalmatic/dalmatic1(1,2).glb')

  useEffect(() => {
    if (orbitRef.current) {
      orbitRef.current.reset()
      orbitRef.current.target.y = -3.5
      orbitRef.current.maxDistance = 7;
      orbitRef.current.minDistance = 4.5;
    }
  }, [props.reset])

  useEffect(() => {
    // console.log(refOrph.currnt)
  }, [props.orphey])

  const fabricArray = [
    ['ADORNES-BLACK-min.jpg', [1, 1, 1]],
    ['ADORNES-GREEN-min.jpg', [0.6, 0.6, 0.6]],
    ['ADORNES-ORANGE-min.jpg', [0.9, 0.7, 0.9]],
    ['ADORNES-PURPLE-min.jpg', [0.55, 1, 0.55]],
    ['ADORNES-RED-min.jpg', [0.6, 1, 1]],
    ['ADORNES-SILVER-min.jpg', [1, 1, 0.8]],
    ['BRUGIA92-10-min.jpg', [1, 1, 1]],
    ['BRUGIA92-14-min.jpg', [1, 1, 1]],
    ['BRUGIA92-20-min.jpg', [1, 1, 1]],
    ['BRUGIA92-30-min.jpg', [1, 1, 1]],
    ['BRUGIA92-40-min.jpg', [1, 1, 1]],
    ['BRUGIA92-50-min.jpg', [1, 1, 1]],
    ['CANTATE-min.jpg', [1, 1, 1]],
    ['DUOMO-CREAM-min.jpg', [1, 1, 1]],
    ['DUOMO-GREEN-min.jpg', [1, 1, 1]],
    ['DUOMO-PURPLE-min.jpg', [1, 1, 1]],
    ['DUOMO-RED-min.jpg', [1, 1, 1]],
    ['DUPION-25-10-min.jpg', [1, 1, 1]],
    ['DUPION-25-14-min.jpg', [0.9, 0.9, 0.7]],
    ['DUPION-25-20-min.jpg', [0.35, 1, 1]],
    ['DUPION-25-23-min.jpg', [0.45, 1, 1]],
    ['DUPION-25-30-min.jpg', [0.7, 0.4, 0.8]],
    ['DUPION-25-33-min.jpg', [0.9, 0.5, 0.8]],
    ['DUPION-25-40-min.jpg', [6.5, 0.1, 1.4]],
    ['DUPION-25-42-min.jpg', [0.7, 1.5, 0.6]],
    ['DUPION-25-59-min.jpg', [0.85, 0.7, 0.6]],
    ['DUPION-25-81-min.jpg', [0.5, 0.5, 0.6]],
    ['PIUS-54-10-min.jpg', [1, 1, 1]],
    ['PIUS-54-14-min.jpg', [1, 1, 1]],
    ['PIUS-54-20-min.jpg', [1, 1, 1]],
    ['PIUS-54-30-min.jpg', [1, 1, 1]],
    ['PIUS-54-40-min.jpg', [1, 1, 1]],
    ['PIUS-54-101-min.jpg', [1, 1, 1]],
  ]

  function textureModel() {
    switch (props.texture) {
      case props.texture: return `./models/fabricNew/${fabricArray[props.texture][0]}`
    }
  }

  const orpheyStyleArrayDalmatic = [
    [
        '30-3002_2060_86mm_wide S1.png',
        '30-3002_2060_86mm_wide S2 - DALMATIC.png',
        '30-3002_2060_86mm_wide S3 - DALMATIC.png',
        '30-3002_2060_86mm_wide S4 - DALMATIC.png',
        '30-3002_2060_86mm_wide S5.png',
    ],
    [
        '30-3002_2060_86mm_wide S1.png',
        '30-3002_2060_86mm_wide S2 - DALMATIC.png',
        '30-3002_2060_86mm_wide S3 - DALMATIC.png',
        '30-3002_2060_86mm_wide S4 - DALMATIC.png',
        '30-3002_2060_86mm_wide S5.png',
    ],
    [
        '30-3002_3060_86mm_wide.S2 - DALMATICpng.png',
        '30-3002_3060_86mm_wide.S3 - DALMATICpng.png',
        '30-3002_3060_86mm_wide.S4 - DALMATICpng.png',
        '30-3002_3060_86mm_wide S1.png',
        '30-3002_3060_86mm_wide S5.png',
    ],
    [
        '30-3002_4060_86mm_wide S1.png',
        '30-3002_4060_86mm_wide S2 - DALMATIC.png',
        '30-3002_4060_86mm_wide S3 - DALMATIC.png',
        '30-3002_4060_86mm_wide S4 - DALMATIC.png',
        '30-3002_4060_86mm_wide S5.png',
    ],
    [ '30-3002_6020_86mm-wide S1.png',
      '30-3002_6020_86mm-wide S2 - DALMATIC.png',
      '30-3002_6020_86mm-wide S3 - DALMATIC.png',
      '30-3002_6020_86mm-wide S4 - DALMATIC.png',
      '30-3002_6020_86mm-wide S5.png',
    ],
    [ '30-3002_8060_86mm-wide S1.png',
        '30-3002_8060_86mm-wide S2 - DALMATIC.png',
        '30-3002_8060_86mm-wide S3 - DALMATIC.png',
        '30-3002_8060_86mm-wide S4 - DALMATIC.png',
        '30-3002_8060_86mm-wide S5.png',],
    [ '30-3006_2060_76mm_wide S1.png',
      '30-3006_2060_76mm_wide S2 - DALMATIC.png',
      '30-3006_2060_76mm_wide S3 - DALMATIC.png',
      '30-3006_2060_76mm_wide S4 - DALMATIC.png',
      '30-3006_2060_76mm_wide S5.png',],
      [ '30-3006_3060_76mm_wide S1.png',
        '30-3006_3060_76mm_wide S2 - DALMATIC.png',
        '30-3006_3060_76mm_wide S3 - DALMATIC.png',
        '30-3006_3060_76mm_wide S4 - DALMATIC.png',
        '30-3006_3060_76mm_wide S5.png',],
    [ '30-3006_4060_76mm_wide S1.png',
      '30-3006_4060_76mm_wide S2 - DALMATIC.png',
      '30-3006_4060_76mm_wide S3 - DALMATIC.png',
      '30-3006_4060_76mm_wide S4 - DALMATIC.png',
      '30-3006_4060_76mm_wide S5.png',],
      ['30-3006_8060_76mm_wide S1.png',
        '30-3006_8060_76mm_wide S2 - DALMATIC.png',
        '30-3006_8060_76mm_wide S3 - DALMATIC.png',
        '30-3006_8060_76mm_wide S4 - DALMATIC.png',
        '30-3006_8060_76mm_wide S5.png',],
      [ '30-3010_1460_65mm_wide S1.png',
        '30-3010_1460_65mm_wide S2 - DALMATIC.png',
        '30-3010_1460_65mm_wide S3 - DALMATIC.png',
        '30-3010_1460_65mm_wide S4 - DALMATIC.png',
        '30-3010_1460_65mm_wide S5.png',],
      [ '30-3010_2060_65mm_wide S1.png',
        '30-3010_2060_65mm_wide S2 - DALMATIC.png',
        '30-3010_2060_65mm_wide S3 - DALMATIC.png',
        '30-3010_2060_65mm_wide S4 - DALMATIC.png',
        '30-3010_2060_65mm_wide S5.png',],
      [ '30-3010_3060_65mm_wide S1.png',
        '30-3010_3060_65mm_wide S2 - DALMATIC.png',
        '30-3010_3060_65mm_wide S3 - DALMATIC.png',
        '30-3010_3060_65mm_wide S4 - DALMATIC.png',
        '30-3010_3060_65mm_wide S5.png',],
    [
      '30-3010_4060_65mm_wide S1.png',
      '30-3010_4060_65mm_wide S2 - DALMATIC.png',
      '30-3010_4060_65mm_wide S3 - DALMATIC.png',
      '30-3010_4060_65mm_wide S4 - DALMATIC.png',
      '30-3010_4060_65mm_wide S5.png',],
    [
      '30-3014_5060_65mm_wide S1.png',
      '30-3014_5060_65mm_wide S2 - DALMATIC.png',
      '30-3014_5060_65mm_wide S3 - DALMATIC.png',
      '30-3014_5060_65mm_wide S4 - DALMATIC.png',
      '30-3014_5060_65mm_wide S5.png',],
    [  '31-3122_2060_75mm-wide S1.png',
        '31-3122_2060_75mm-wide S2 - DALMATIC.png',
        '31-3122_2060_75mm-wide S3 - DALMATIC.png',
        '31-3122_2060_75mm-wide S4 - DALMATIC.png',
        '31-3122_2060_75mm-wide S5.png',],
    [
      '31-3122_3060_75mm-wide S1.png',
      '31-3122_3060_75mm-wide S2 - DALMATIC.png',
      '31-3122_3060_75mm-wide S3 - DALMATIC.png',
      '31-3122_3060_75mm-wide S4 - DALMATIC.png',
      '31-3122_3060_75mm-wide S5.png',],
    [ '31-3122_4060_75mm-wide S1.png',
      '31-3122_4060_75mm-wide S2 - DALMATIC.png',
      '31-3122_4060_75mm-wide S3 - DALMATIC.png',
      '31-3122_4060_75mm-wide S4 - DALMATIC.png',
      '31-3122_4060_75mm-wide S5.png',]
  ];
  const orpheyArray = [
    '30-3002_2060_86mm_wide S1.png',
    '30-3002_2060_86mm_wide S1.png',
    '30-3002_2060_86mm_wide S2 - DALMATIC.png',
    '30-3002_2060_86mm_wide S3 - DALMATIC.png',
    '30-3002_2060_86mm_wide S4 - DALMATIC.png',
    '30-3002_2060_86mm_wide S5.png',
    '30-3002_2060_86mm_wide S6.png',
    '30-3002_2060_86mm_wide S7.png',
    '30-3002_2060_86mm_wide S8.png',
    '30-3002_3060_86mm_wide.S2 - DALMATICpng.png',
    '30-3002_3060_86mm_wide.S3 - DALMATICpng.png',
    '30-3002_3060_86mm_wide.S4 - DALMATICpng.png',
    '30-3002_3060_86mm_wide S1.png',
    '30-3002_3060_86mm_wide S5.png',
    '30-3002_3060_86mm_wide S6.png',
    '30-3002_3060_86mm_wide S7.png',
    '30-3002_3060_86mm_wide S8.png',
    '30-3002_4060_86mm_wide S1.png',
    '30-3002_4060_86mm_wide S2 - DALMATIC.png',
    '30-3002_4060_86mm_wide S3 - DALMATIC.png',
    '30-3002_4060_86mm_wide S4 - DALMATIC.png',
    '30-3002_4060_86mm_wide S5.png',
    '30-3002_4060_86mm_wide S6.png',
    '30-3002_4060_86mm_wide S7.png',
    '30-3002_4060_86mm_wide S8.png',
    '30-3002_6020_86mm-wide S1.png',
    '30-3002_6020_86mm-wide S2 - DALMATIC.png',
    '30-3002_6020_86mm-wide S3 - DALMATIC.png',
    '30-3002_6020_86mm-wide S4 - DALMATIC.png',
    '30-3002_6020_86mm-wide S5.png',
    '30-3002_6020_86mm-wide S6.png',
    '30-3002_6020_86mm-wide S7.png',
    '30-3002_6020_86mm-wide S8.png',
    '30-3002_8060_86mm-wide S1.png',
    '30-3002_8060_86mm-wide S2 - DALMATIC.png',
    '30-3002_8060_86mm-wide S3 - DALMATIC.png',
    '30-3002_8060_86mm-wide S4 - DALMATIC.png',
    '30-3002_8060_86mm-wide S5.png',
    '30-3002_8060_86mm-wide S6.png',
    '30-3002_8060_86mm-wide S7.png',
    '30-3002_8060_86mm-wide S8.png',
    '30-3006_2060_76mm_wide S1.png',
    '30-3006_2060_76mm_wide S2 - DALMATIC.png',
    '30-3006_2060_76mm_wide S3 - DALMATIC.png',
    '30-3006_2060_76mm_wide S4 - DALMATIC.png',
    '30-3006_2060_76mm_wide S5.png',
    '30-3006_2060_76mm_wide S6.png',
    '30-3006_2060_76mm_wide S7.png',
    '30-3006_2060_76mm_wide S8.png',
    '30-3006_3060_76mm_wide S1.png',
    '30-3006_3060_76mm_wide S2 - DALMATIC.png',
    '30-3006_3060_76mm_wide S3 - DALMATIC.png',
    '30-3006_3060_76mm_wide S4 - DALMATIC.png',
    '30-3006_3060_76mm_wide S5.png',
    '30-3006_3060_76mm_wide S6.png',
    '30-3006_3060_76mm_wide S7.png',
    '30-3006_3060_76mm_wide S8.png',
    '30-3006_4060_76mm_wide S1.png',
    '30-3006_4060_76mm_wide S2 - DALMATIC.png',
    '30-3006_4060_76mm_wide S3 - DALMATIC.png',
    '30-3006_4060_76mm_wide S4 - DALMATIC.png',
    '30-3006_4060_76mm_wide S5.png',
    '30-3006_4060_76mm_wide S6.png',
    '30-3006_4060_76mm_wide S7.png',
    '30-3006_4060_76mm_wide S8.png',
    '30-3006_8060_76mm_wide S1.png',
    '30-3006_8060_76mm_wide S2 - DALMATIC.png',
    '30-3006_8060_76mm_wide S3 - DALMATIC.png',
    '30-3006_8060_76mm_wide S4 - DALMATIC.png',
    '30-3006_8060_76mm_wide S5.png',
    '30-3006_8060_76mm_wide S6.png',
    '30-3006_8060_76mm_wide S7.png',
    '30-3006_8060_76mm_wide S8.png',
    '30-3010_1460_65mm_wide S1.png',
    '30-3010_1460_65mm_wide S2 - DALMATIC.png',
    '30-3010_1460_65mm_wide S3 - DALMATIC.png',
    '30-3010_1460_65mm_wide S4 - DALMATIC.png',
    '30-3010_1460_65mm_wide S5.png',
    '30-3010_1460_65mm_wide S6.png',
    '30-3010_1460_65mm_wide S7.png',
    '30-3010_1460_65mm_wide S8.png',
    '30-3010_2060_65mm_wide S1.png',
    '30-3010_2060_65mm_wide S2 - DALMATIC.png',
    '30-3010_2060_65mm_wide S3 - DALMATIC.png',
    '30-3010_2060_65mm_wide S4 - DALMATIC.png',
    '30-3010_2060_65mm_wide S5.png',
    '30-3010_2060_65mm_wide S6.png',
    '30-3010_2060_65mm_wide S7.png',
    '30-3010_2060_65mm_wide S8.png',
    '30-3010_3060_65mm_wide S1.png',
    '30-3010_3060_65mm_wide S2 - DALMATIC.png',
    '30-3010_3060_65mm_wide S3 - DALMATIC.png',
    '30-3010_3060_65mm_wide S4 - DALMATIC.png',
    '30-3010_3060_65mm_wide S5.png',
    '30-3010_3060_65mm_wide S6.png',
    '30-3010_3060_65mm_wide S7.png',
    '30-3010_3060_65mm_wide S8.png',
    '30-3010_4060_65mm_wide S1.png',
    '30-3010_4060_65mm_wide S2 - DALMATIC.png',
    '30-3010_4060_65mm_wide S3 - DALMATIC.png',
    '30-3010_4060_65mm_wide S4 - DALMATIC.png',
    '30-3010_4060_65mm_wide S5.png',
    '30-3010_4060_65mm_wide S6.png',
    '30-3010_4060_65mm_wide S7.png',
    '30-3010_4060_65mm_wide S8.png',
    '30-3014_5060_65mm_wide S1.png',
    '30-3014_5060_65mm_wide S2 - DALMATIC.png',
    '30-3014_5060_65mm_wide S3 - DALMATIC.png',
    '30-3014_5060_65mm_wide S4 - DALMATIC.png',
    '30-3014_5060_65mm_wide S5.png',
    '30-3014_5060_65mm_wide S6.png',
    '30-3014_5060_65mm_wide S7.png',
    '30-3014_5060_65mm_wide S8.png',
    '31-3122_2060_75mm-wide S1.png',
    '31-3122_2060_75mm-wide S2 - DALMATIC.png',
    '31-3122_2060_75mm-wide S3 - DALMATIC.png',
    '31-3122_2060_75mm-wide S4 - DALMATIC.png',
    '31-3122_2060_75mm-wide S5.png',
    '31-3122_2060_75mm-wide S6.png',
    '31-3122_2060_75mm-wide S7.png',
    '31-3122_2060_75mm-wide S8.png',
    '31-3122_3060_75mm-wide S1.png',
    '31-3122_3060_75mm-wide S2 - DALMATIC.png',
    '31-3122_3060_75mm-wide S3 - DALMATIC.png',
    '31-3122_3060_75mm-wide S4 - DALMATIC.png',
    '31-3122_3060_75mm-wide S5.png',
    '31-3122_3060_75mm-wide S6.png',
    '31-3122_3060_75mm-wide S7.png',
    '31-3122_3060_75mm-wide S8.png',
    '31-3122_4060_75mm-wide S1.png',
    '31-3122_4060_75mm-wide S2 - DALMATIC.png',
    '31-3122_4060_75mm-wide S3 - DALMATIC.png',
    '31-3122_4060_75mm-wide S4 - DALMATIC.png',
    '31-3122_4060_75mm-wide S5.png',
    '31-3122_4060_75mm-wide S6.png',
    '31-3122_4060_75mm-wide S7.png',
    '31-3122_4060_75mm-wide S8.png',
  ];
  function orpheyModel() {
    switch (props.orphey) {
      case props.orphey: return `./models/orpheysNew/${orpheyStyleArrayDalmatic[props.orphey][props.orpheyStyle]}`
    }
  }

  const orphey = useLoader(THREE.TextureLoader, orpheyModel())
  orphey.repeat.set(1, 1)
  orphey.center.set(.5, .5)
  if (orphey.rotation === 1.57) {
    orphey.rotation = 0;
  }
  const orpheyFront = (
    <mesh
        ref={refOrph}
      renderOrder={1}
      geometry={nodes.Dalmatic_FRONT.geometry}
        scale={[500, 500, 500]}
      position={[0.04, -29.46, 1.5]}>
      <meshStandardMaterial attach="material" map={orphey} transparent={true} side={THREE.DoubleSide} />
    </mesh>
  )
  const orpheyBack = (
    <mesh
      renderOrder={1}
      geometry={nodes.Dalmatic_BACK.geometry}
      scale={[500, 500, 500]}
      position={[0.04, -29.46, 1.5]}>
      <meshStandardMaterial attach="material" map={orphey} transparent={true} side={THREE.DoubleSide} />
    </mesh>
  )

  function medallionFrontModel() {
    // console.log(props.medallionFront, 'props.medallionFront');
    switch (props.medallionFront) {
      case 0: return './models/medallionNew/22-6001_16cm.png';
      case 1: return './models/medallionNew/22-6002_16cm.png';
      case 2: return './models/medallionNew/22-6003_16cm.png';
      case 3: return './models/medallionNew/22-6004_16cm.png';
      case 4: return './models/medallionNew/22-6005_16cm.png';
      case 5: return './models/medallionNew/22-6006_16cm.png';
      case 6: return './models/medallionNew/22-6007_16cm.png';
      case 7: return './models/medallionNew/22-6008_16cm.png';
      case 8: return './models/medallionNew/22-6010_18cm_high.png';
      case 9: return './models/medallionNew/22-6011_18cm_high.png';
      case 10: return './models/medallionNew/22-6013_19cm_high.png';
      case 11: return './models/medallionNew/22-6020_16cm_green.png';
      case 12: return './models/medallionNew/22-6020_16cm_purple.png';
      case 13: return './models/medallionNew/22-6020_16cm_red.png';
      case 14: return './models/medallionNew/22-6023_16cm.png';
      case 15: return './models/medallionNew/22-6030_16cm_high.png';
      case 16: return './models/medallionNew/22-6030_16cm_high_beige.png';
      case 17: return './models/medallionNew/22-6030_16cm_high_purple.png';
      case 18: return './models/medallionNew/22-6030_16cm_high_red.png';
      case 19: return './models/medallionNew/22-6033_16cm.png';
      case 20: return './models/medallionNew/22-6043_16cm.png';
      case 21: return './models/medallionNew/22-6060_7,5cm_high.png';
      case 22: return './models/medallionNew/22-6061_28cm_high_28cm.png';
      case 23: return './models/medallionNew/22-6062_25cm_high.png';
      case 24: return './models/medallionNew/22-6063_10cm_high.png';
      case 25: return './models/medallionNew/22-6065_18cm_high.png';
      case 26: return './models/medallionNew/22-6066_25cm_high.png';
      case 27: return './models/medallionNew/22-6067_15cm_high.png';
      case 28: return './models/medallionNew/22-6067_15cm_high_green.png';
      case 29: return './models/medallionNew/22-6067_15cm_high_purple.png';
      case 30: return './models/medallionNew/22-6080_16cm.png';
      case 31: return './models/medallionNew/22-6081_16cm.png';
      case 32: return './models/medallionNew/22-6083_16cm.png';
      case 33: return './models/medallionNew/22-6001_16cm.png';
    }
  }

  function medallionBackModel() {
    switch (props.medallionBack) {
      case 0: return './models/medallionNew/22-6001_16cm.png';
      case 1: return './models/medallionNew/22-6002_16cm.png';
      case 2: return './models/medallionNew/22-6003_16cm.png';
      case 3: return './models/medallionNew/22-6004_16cm.png';
      case 4: return './models/medallionNew/22-6005_16cm.png';
      case 5: return './models/medallionNew/22-6006_16cm.png';
      case 6: return './models/medallionNew/22-6007_16cm.png';
      case 7: return './models/medallionNew/22-6008_16cm.png';
      case 8: return './models/medallionNew/22-6010_18cm_high.png';
      case 9: return './models/medallionNew/22-6011_18cm_high.png';
      case 10: return './models/medallionNew/22-6013_19cm_high.png';
      case 11: return './models/medallionNew/22-6020_16cm_green.png';
      case 12: return './models/medallionNew/22-6020_16cm_purple.png';
      case 13: return './models/medallionNew/22-6020_16cm_red.png';
      case 14: return './models/medallionNew/22-6023_16cm.png';
      case 15: return './models/medallionNew/22-6030_16cm_high.png';
      case 16: return './models/medallionNew/22-6030_16cm_high_beige.png';
      case 17: return './models/medallionNew/22-6030_16cm_high_purple.png';
      case 18: return './models/medallionNew/22-6030_16cm_high_red.png';
      case 19: return './models/medallionNew/22-6033_16cm.png';
      case 20: return './models/medallionNew/22-6043_16cm.png';
      case 21: return './models/medallionNew/22-6060_7,5cm_high.png';
      case 22: return './models/medallionNew/22-6061_28cm_high_28cm.png';
      case 23: return './models/medallionNew/22-6062_25cm_high.png';
      case 24: return './models/medallionNew/22-6063_10cm_high.png';
      case 25: return './models/medallionNew/22-6065_18cm_high.png';
      case 26: return './models/medallionNew/22-6066_25cm_high.png';
      case 27: return './models/medallionNew/22-6067_15cm_high.png';
      case 28: return './models/medallionNew/22-6067_15cm_high_green.png';
      case 29: return './models/medallionNew/22-6067_15cm_high_purple.png';
      case 30: return './models/medallionNew/22-6080_16cm.png';
      case 31: return './models/medallionNew/22-6081_16cm.png';
      case 32: return './models/medallionNew/22-6083_16cm.png';
      case 33: return './models/medallionNew/22-6001_16cm.png';
    }
  }

  const medallionFrontType = useLoader(THREE.TextureLoader, medallionFrontModel())
  const medallionBackType = useLoader(THREE.TextureLoader, medallionBackModel())
  medallionFrontType.repeat.set(.9, 1)
  medallionFrontType.center.set(.5, .5)
  medallionFrontType.flipY = false
  medallionBackType.repeat.set(.9, 1)
  medallionBackType.center.set(.5, .5)
  medallionBackType.flipY = false
  // console.log(medallionFrontType)
  const medallionFront = (
    <mesh
      renderOrder={2}
      geometry={nodes.Dalmatic_FRONT.geometry}
      scale={[500, 500, 500]}
      position={[0.04, -29.46, 1.5]}>
      <meshStandardMaterial attach="material" map={medallionFrontType} transparent={true} side={THREE.DoubleSide} />
    </mesh>
  )
  const medallionBack = (
    <mesh
      renderOrder={2}
      geometry={nodes.Dalmatic_BACK.geometry}
      scale={[500, 500, 500]}
      position={[0.04, -29.46, 1.5]}>
      <meshStandardMaterial attach="material" map={medallionBackType} transparent={true} side={THREE.DoubleSide} />
    </mesh>
  )

  const texture = useLoader(THREE.TextureLoader, textureModel())
  texture.center.set(.5, .5)
  texture.repeat.set(.8, 1)
  texture.rotation = Math.PI
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        renderOrder={0}
        geometry={nodes.Dalmatic_FRONT.geometry}
        scale={[500, 500, 500]}
        position={[0.04, -29.46, 1.5]}>
        <meshStandardMaterial attach="material" color={fabricArray[props.texture][1]} map={texture} transparent={false} side={THREE.FrontSide} metalness={props.texture >= 13 && props.texture <= 16 ? 0.7 : 0} roughness={props.texture >= 13 && props.texture <= 16 ? 0.6 : 0.85} />
      </mesh>
      <mesh
          renderOrder={0}
          geometry={nodes.Dalmatic_FRONT_inside.geometry}
          scale={[500, 500, 500]}
          position={[0.04, -29.80, 1.5]}>
        <meshStandardMaterial attach="material" color={0x76777a} map={texture} transparent={false} side={THREE.FrontSide} />
      </mesh>
      {props.orphey > 0 ? orpheyFront : null}
      {props.medallionFront > 0 ? medallionFront : null}
      <mesh
        renderOrder={0}
        geometry={nodes.Dalmatic_BACK.geometry}
        scale={[500, 500, 500]}
        position={[0.04, -29.46, 1.5]}>
        <meshStandardMaterial attach="material" color={fabricArray[props.texture][1]} map={texture} transparent={false} side={THREE.DoubleSide} metalness={props.texture >= 13 && props.texture <= 16 ? 0.7 : 0} roughness={props.texture >= 13 && props.texture <= 16 ? 0.6 : 0.85} />
      </mesh>
      <mesh
          renderOrder={0}
          geometry={nodes.Dalmatic_BACK_inside.geometry}
          scale={[500, 500, 500]}
          position={[0.02, -29.80, 1.5]}>
        <meshStandardMaterial attach="material" color={0x76777a} map={texture} transparent={false} side={THREE.DoubleSide} />
      </mesh>
      {props.orphey > 0 ? orpheyBack : null}
      {props.medallionBack > 0 ? medallionBack : null}
      <mesh
        geometry={nodes.collar_3.geometry}
        position={[0.04, -29.46, 1.5]}
        scale={props.collar == 3 ? 500 : 0}>
        <meshStandardMaterial attach="material" map={texture} transparent={false} side={THREE.DoubleSide} />
      </mesh>
      <mesh
        geometry={nodes.collar_4.geometry}
        position={[0.04, -29.46, 1.5]}
        scale={props.collar == 4 ? 500 : 0}>
        <meshStandardMaterial attach="material" map={texture} transparent={false} side={THREE.DoubleSide} />
      </mesh>
      <mesh
        geometry={nodes.collar_6.geometry}
        position={[0.04, -29.5, 1.5]}
        scale={props.collar == 6 ? 500 : 0}>
        <meshStandardMaterial attach="material" map={texture} transparent={false} side={THREE.DoubleSide} />
      </mesh>
      <OrbitControls ref={orbitRef} enableZoom={true} />
    </group>
  )
}

useGLTF.preload('./models/Dalmatic/dalmatic1(1,2).glb')
